const app = global.app = (global.app || { config: {} })
require('script-loader!./lib/contact.js')
require('script-loader!./lib/employees.js')
require('script-loader!./lib/googleMaps.js')
require('script-loader!./lib/i18n.js')
require('script-loader!./lib/methods.js')
require('script-loader!./lib/megaMenu.js')
require('script-loader!./lib/menu-description.js')
require('script-loader!./lib/products.js')
require('script-loader!./lib/rebox.js')
require('script-loader!./lib/scroll.js')
require('script-loader!./lib/toggle.js')
require('script-loader!./lib/whitepaper.js')
require('script-loader!./lib/publication.js')
require('script-loader!./lib/cleverreach.js')

$(document).ready(function () {
  'use strict'

  $('.breadcrumb-list').filter(function () {
    return this.childNodes.length > 3
  }).addClass('show')

  $('.simple').filter(function () {
    return this.childNodes.length >= 3
  }).addClass('three')
  
  $('.solutions-collection--wrapper.other').filter(function () {
    return this.childNodes.length > 6
  }).addClass('twoColumns')

  $('.arrow-down').on('click', function (event) {
    if (this.hash !== '') {
      const hash = this.hash

      $('html, body').animate({
        scrollTop: $(hash).offset().top - $('.header').height() - 40
      }, 500, function () {

      })
      return false
    }
  })

  $('.neos-nodetypes-text a').on('click', function (event) {
    if (this.hash !== '') {
      const hash = this.hash

      $('html, body').animate({
        scrollTop: $(hash).offset().top - $('.header').height() - 80
      }, 500, function () {

      })
      return false
    }
  })

  $('.btn').on('click', function (event) {
    if (this.hash !== '') {
      const hash = this.hash

      $('html, body').animate({
        scrollTop: $(hash).offset().top - $('.header').height() - 80
      }, 500, function () {

      })
      return false
    }
  })

  $('.neos-nodetypes-html-html a[*|href^="#"]').on('click', function (e) {
    e.preventDefault()

    const target = $(this).attr('xlink:href')
    const $target = $(target)

    $('html, body').stop().animate({
      scrollTop: $target.offset().top - $('.header').height()
    }, 500, function () {

    })
    return false
  })

  $('.teaser-animated--wrapper.backend .infos').removeAttr("href");

  app.openInNewTab = function (selector) {
    $(selector).attr('target', '_blank')
  }

  app.contact('#contact-form', {
    focus: false,
    hidden: [
      { name: 'location', value: $('html').data('location') }
    ]
  })
  app.megaMenu()
  app.menuDescription({
    items: '.header-nav-list li > a[data-content]',
    currentItem: '.header-nav-list li.open > a[data-content]',
    description: '.header-nav-description'
  })
  app.rebox('.headstart-nodetypes-thumbimage')
  app.scroll.toggleBelowFold()
  app.scroll.scrollSpy('ul.header-sub-nav-list:visible', { offset: 345 })
  app.scroll.smoothScroll('ul.header-sub-nav-list a[href*="#"]', { offset: 268 })
  app.scroll.smoothScroll('.cycle-svg a[*|href^="#"]', { offset: 268 })
  app.scroll.smoothScroll('div.btn-circle-wrapper [href*="#"]', { offset: 320 })
  app.scroll.smoothScroll('div.header-call-to-action-button [href*="#"]', { offset: 120 })
  app.scroll.smoothScroll('.teaser-animated--wrapper a[href*="#"]', { offset: 120 })
  app.openInNewTab('.header-meta-nav a[href^=http]')
  app.openInNewTab('.footer-nav-landing a')
  app.methods()
  app.employees()
  app.products()
  app.whitepapers()
  app.publications()

  // Slider
  const sliderConfigurations = [
    {
      sliderClass: '.glider',
      slidesToShow: 3,
      slidesToScroll: 3,
      draggable: true,
      dots: '.dots',
      arrows: {
        prev: '.glider-prev',
        next: '.glider-next'
      },
      responsive: [
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]
    },
    {
      sliderClass: '.solutions-slider .solutions-collection--wrapper',
      slidesToShow: 3,
      slidesToScroll: 3,
      draggable: true,
      dots: '.dots',
      arrows: {
        prev: '.glider-prev',
        next: '.glider-next'
      },
      responsive: [
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]
    }
  ]

  sliderConfigurations.forEach(config => {
    // eslint-disable-next-line no-new
    const sliderElement = document.querySelector(config.sliderClass);
    if (sliderElement) {
      new Glider(document.querySelector(config.sliderClass), {
        slidesToShow: config.slidesToShow,
        slidesToScroll: config.slidesToScroll,
        draggable: config.draggable,
        dots: config.dots,
        arrows: {
          prev: config.arrows.prev,
          next: config.arrows.next
        },
        responsive: config.responsive
      })
    }
  })
})

$(document).ready(secondNavDistance);
$(window).on('resize',secondNavDistance);

function secondNavDistance() {
  let secondNavWrapper = document.querySelector('.header.honeycumb-two-level .header-sub-nav-content');

  if (secondNavWrapper !== null) {
    let wrapperHeight = secondNavWrapper.offsetHeight;
    $(".content").css("padding-top", (wrapperHeight));
  }
}

$(document).ready(function () {
  // Add class 'active' to section when near top of viewport
  const animationRight = document.querySelectorAll('.animationRight')
  const animationLeft = document.querySelectorAll('.animationLeft')
  const teaserCollection = document.querySelectorAll('.teaser-collection--wrapper')

  window.onscroll = function () {
    animationRight.forEach(section => {
      if (section.getBoundingClientRect().top <= 900) {
        section.classList.add('active')
      }   else if ($(window).width() < 960 && section.getBoundingClientRect().top <= 20) {
        section.classList.add('active')
      }
    });

    animationLeft.forEach(section => {
      if (section.getBoundingClientRect().top <= 900) {
        section.classList.add('active')
      }
    })

    teaserCollection.forEach(section => {
      if (section.getBoundingClientRect().top <= 500) {
        section.classList.add('active')
      }
    })
  }
})


var newsletterCTA = $('.newsletter--wrapper .cta')
var newsletterContent = $('.newsletter--wrapper .content')
var newsletterCloseBtn = $('.newsletter--wrapper .close')

$(newsletterCTA).on('click', function(){
  $(newsletterContent).slideDown();
  $(newsletterCTA).hide();
});


$(newsletterCloseBtn).on('click', function(){
  $(newsletterContent).slideUp();
  $(newsletterCTA).fadeIn(100);
  localStorage.setItem('hidePopup', true)
});

$.wait = function( callback, seconds){
  return window.setTimeout( callback, seconds * 1000 );
}

if (localStorage.getItem('hidePopup')) {
  $(newsletterContent).hide()
} else {
  $.wait( function(){ $(newsletterContent).slideDown();  }
  , 3);
  $(newsletterCTA).hide();
}

const whitepaperSections = document.querySelectorAll('.whitepaper-section');

whitepaperSections.forEach(section => {
    const loadmore = section.querySelector('.btn-blue-outline.load-more');
    const tabScroll = section.querySelector('.whitepaper-collection--wrapper');
    if (!loadmore) return; 
  
    let currentItems = 6;
    const container = section.querySelector('.whitepaper-collection--wrapper');
    const elementList = [...container.querySelectorAll('.whitepaper-studies-teaser--highlight')];
    const loadmoreAllButton = section.querySelector('.btn-blue-outline.load-all');

    for (let i = 0; i < Math.min(currentItems, elementList.length); i++) {
        elementList[i].classList.add('show');
    }

    if (elementList.length <= 6) {
        loadmore.style.display = 'none';
    }

    loadmore.addEventListener('click', (e) => {
        container.classList.add('collapse');

        for (let i = currentItems; i < Math.min(currentItems + 3, elementList.length); i++) {
            elementList[i].classList.add('show');
        }
        currentItems += 6;

        if (currentItems >= elementList.length) {
            loadmore.style.display = 'none';
            loadmoreAllButton.style.display = 'inline-block';
        }
    });

    loadmoreAllButton.addEventListener('click', () => {
        elementList.forEach((element, index) => {
            if (index >= 6) {
                element.classList.remove('show');
            }
        });

        currentItems = 6;
        for (let i = 0; i < Math.min(currentItems, elementList.length); i++) {
            elementList[i].classList.add('show');
        }

        loadmore.style.display = 'inline-block';
        loadmoreAllButton.style.display = 'none';
        
        window.scrollTo({
          'behavior': 'smooth',
          'left': 0,
          'top': container.offsetTop - 80
        });
    });
});

